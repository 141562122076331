import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { EnfermeriaService } from '../enfermeria.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-modal-preregistro',
  templateUrl: './modal-preregistro.page.html',
  styleUrls: ['./modal-preregistro.page.scss'],
})
export class ModalPreregistroPage implements OnInit {

  idPreregistro = null;
  urlImagen: string;
  errorMsg: string;
  model: any;

  basePath: string = environment.baseUrl;

  constructor(private navParams: NavParams, 
    private modalCtrl: ModalController,
    private enfermeriaService: EnfermeriaService) { }

  ngOnInit() {
    this.idPreregistro = this.navParams.get('id');
    this.errorMsg = this.navParams.get('error');
    this.getPreregistro();
  }

  getPreregistro(): void {
    this.enfermeriaService.getPreregistro(this.idPreregistro)
      .subscribe(model => {
        this.urlImagen = this.basePath + model.url;
        this.model = model;
      }, (err: HttpErrorResponse) => {
        this.urlImagen = null;
      });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
