import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule }    from '@angular/common/http';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './auth.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ModalPreregistroPageModule } from './modal-preregistro/modal-preregistro.module';

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ImageModalPageModule } from './image-modal/image-modal.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    ModalPreregistroPageModule,
    ImageModalPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BarcodeScanner,
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    File,
    FileOpener,
    FileTransfer,
    DocumentViewer,
    OneSignal
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
