import { Component } from '@angular/core';

import { Platform, NavController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public appPages = [
    {
      title: 'Programa',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Preregistro',
      url: '/registro',
      icon: 'person-add'
    },
    {
      title: 'Checkin',
      url: '/checkin',
      icon: 'checkbox-outline'
    },
    {
      title: 'Perfil',
      url: '/perfil',
      icon: 'person'
    },
    {
      title: 'Iniciar sesión',
      url: '/login',
      icon: 'key'
    },
    {
      title: 'Pagos',
      url: '/pagos',
      icon: 'cash'
    },
    {
      title: 'Codigo Para Evento',
      url: '/qr',
      icon: 'barcode'
    },
    {
      title: 'Reportes',
      url: '/reportes',
      icon: 'document'
    },
    {
      title: 'Gafetes',
      url: '/gafetes',
      icon: 'card'
    },
    {
      title: 'Generar Constancia',
      url: '/constancia',
      icon: 'card'
    },
    {
      title: 'Registro Rapido',
      url: '/preregistro-rapido',
      icon: 'add'
    },
    {
      title: 'Usuarios',
      url: '/usuarios',
      icon: 'person'
    },
    {
      title: 'Becados',
      url: '/becarios',
      icon: 'people'
    },
    {
      title: 'Cambiar Contraseña',
      url: '/pass',
      icon: 'lock'
    },
    {
      title: 'Constancia',
      url: '/consulta-constancia',
      icon: 'document'
    },
    {
      title: 'Constancia Taller/Simposio',
      url: '/consulta-taller-constancia',
      icon: 'document'
    },
    {
      title: 'Acerca De',
      url: '/acerca',
      icon: 'information-circle-outline'
    }

  ];


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
      if (this.platform.is('cordova')) {
        this.setupPush();
       }
    });
  }


  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('38040269-4c49-45a4-91a0-4c8a6343657f', '953520145195');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      this.showAlert(title, msg, 'Cerrar' /*additionalData.task*/);
      // this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      
      let msg = data.notification.payload.body;
      let title = data.notification.payload.title;
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      this.showAlert(title, msg, 'Cerrar'  /*additionalData.task*/);
    });
 
    this.oneSignal.endInit();
  }
 
  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }


  hasPermiso(itemMenu) : boolean {
    var tipoUsuario = localStorage.getItem('tipoUsuario');
    var permisosNormal = ['Programa', 'Perfil', 'Pagos', 'Codigo Para Evento', 'Generar Constancia', 'Cambiar Contraseña'];
    var permisosNoUser = ['Preregistro', 'Iniciar sesión', 'Constancia', 'Constancia Taller/Simposio'];
    var permisosAdmin = ['Programa', 'Pagos', 'Checkin', 'Reportes','Usuarios'];
    var permisosReportes = ['Pagos', 'Reportes'];
    var permisosExpositor = ['Programa'];
    var permisosAdmin = ['Programa', 'Pagos', 'Registro Rapido', 'Checkin', 'Reportes','Usuarios', 'Becados', 'Gafetes'];
    var permisosPagos = ['Pagos', 'Registro Rapido'];
    var permisosCheckin = ['Checkin'];
    var permisosColegio = ['Programa', 'Perfil', 'Codigo Para Evento', 'Cambiar Contraseña'];
    // No loggeado
    switch (tipoUsuario) {
      case 'Normal':
        return this.inArray(itemMenu.title, permisosNormal);
      case 'Pagos':
        return this.inArray(itemMenu.title, permisosPagos);
      case 'Admin':
        return this.inArray(itemMenu.title, permisosAdmin);
      case 'Check-in':
        return this.inArray(itemMenu.title, permisosCheckin);
      case 'Reportes':
        return this.inArray(itemMenu.title, permisosReportes);
      case 'Expositor':
        return this.inArray(itemMenu.title, permisosExpositor);
      case 'Colegio':
          return this.inArray(itemMenu.title, permisosColegio);
      case null:
        return this.inArray(itemMenu.title, permisosNoUser);
    }
  }



  inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
  }

  isLogin() : boolean {
    return localStorage.getItem('tipoUsuario') != null;
  }

  logout() {
    localStorage.removeItem('tipoUsuario');
    localStorage.removeItem('idUsuario');
    localStorage.removeItem('idPreregistro');
    this.navCtrl.navigateRoot('login');
  }
}
