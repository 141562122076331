import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';


const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-localization' : 'es' });
const httpOptions = {
  headers: headers
};

@Injectable({
  providedIn: 'root'
})
export class EnfermeriaService {

  private rootUrl = environment.baseUrl + "/api";

  constructor(private http: HttpClient) { }

  /** POST login to the server */
  login(data): Observable<any> {
    return this.http.post(this.rootUrl + '/login', data, httpOptions);
  }

  /** POST login to the server */
  recuperarPass(data): Observable<any> {
    return this.http.post(this.rootUrl + '/login/recover', data, httpOptions);
  }

  /** POST login to the server */
  changePass(id, data): Observable<any> {
    return this.http.post(this.rootUrl + '/usuarios/change-pass/' + id, data, httpOptions);
  }


/** GET checkins to the server */
  getPreregistros(data, page = 1): Observable<any> {
    return this.http.get(this.rootUrl + '/preregistros' + '?page=' + page, {params: data} );
  }

  /** GET checkins to the server */
  getBecarios( page = 1, data): Observable<any> {
    return this.http.get(this.rootUrl + '/preregistros-becarios' + '?page=' + page, {
      headers: headers,
      params: data,
    });
  }

  /** GET checkins to the server */
  checkPreregistroUnico(data): Observable<any> {
    return this.http.get(this.rootUrl + '/preregistros/checar-unico', {
      headers: headers,
      params: data,
    });
  }

  /** GET checkins to the server */
  getGafetes( page = 1, data): Observable<any> {
    return this.http.get(this.rootUrl + '/preregistros-gafetes' + '?page=' + page, {
      headers: headers,
      params: data,
    });
  }

  /** GET checkins to the server */
  getRptGafetes(data): Observable<any> {
    return this.http.post(this.rootUrl + '/reportes/gafetes', data, httpOptions);
  }
  
  /** GET checkins to the server */
  getConstancias( page = 1, data): Observable<any> {
    return this.http.get(this.rootUrl + '/constancias' + '?page=' + page, {
      headers: headers,
      params: data,
    });
  }

  /** GET checkins to the server */
  getRptConstancias(data): Observable<any> {
    return this.http.post(this.rootUrl + '/constancias/reporte', data, httpOptions);
  } 

  /** POST login to the server */
  addPreregistro(data): Observable<any> {
    return this.http.post(this.rootUrl + '/preregistros', data, httpOptions);
  }

  addPreregistroRapido(data: FormData): Observable<any> {
    return this.http.post(this.rootUrl + '/preregistros-rapido', data);
  }

  getPreregistro(id): Observable<any> {
    return this.http.get(this.rootUrl + '/preregistros/' + id, httpOptions);
  }

  updatePreregistro(id, data): Observable<any> {
    return this.http.post(this.rootUrl + '/preregistros/' + id, data);
  }

  saveFacturacion(id, data): Observable<any> {
    return this.http.post(this.rootUrl + '/preregistros/factura/' + id, data, httpOptions);
  }

  getRptPreregistros(data) : Observable<any> {
    return this.http.post(this.rootUrl + '/reportes/preregistro', data, httpOptions);
  } 

  /** GET checkins to the server */
  getResumenPreregistros(data, page = 1): Observable<any> {
    return this.http.post(this.rootUrl + '/reportes/resumen', data, httpOptions);
  }

  /** POST checkin to the server */
  addCheckin(data): Observable<any> {
    return this.http.post(this.rootUrl + '/checkins', data, httpOptions);
  }

  /** GET checkins to the server */
  getCheckins(page = 1): Observable<any> {
    return this.http.get(this.rootUrl + '/checkins' + '?page=' + page, httpOptions);
  }

  getCountCheckins(): Observable<any> {
    return this.http.get(this.rootUrl + '/checkins/count', httpOptions);
  }

  /** DELETE checkin to the server */
  deleteCheckin(id): Observable<any> {
    return this.http.delete(this.rootUrl + '/checkins/' + id, httpOptions);
  }

  /** GET programas to the server */
  getProgramas(page = 1): Observable<any> {
    return this.http.get(this.rootUrl + '/programas' + '?page=' + page, httpOptions);
  }

  /** POST checkin to the server */
  addPago(data: FormData): Observable<any> {
    return this.http.post(this.rootUrl + '/pagos', data);
  }

  addPagoAdmin(data: FormData): Observable<any> {
    return this.http.post(this.rootUrl + '/pagos/admin', data);
  }

  /** GET pagos to the server */
  getPagos(id, data, page = 1): Observable<any> {
    if (id == 'null' || id == null) {
      return this.http.get(this.rootUrl + '/pagos?page=' + page, {
        headers: headers,
        params: data,
      });
    }
    return this.http.get(this.rootUrl + '/pagos/' + id + '?page=' + page, httpOptions);
  }

  getPago(id): Observable<any> {
    return this.http.get(this.rootUrl + '/pagos/detail/' + id, httpOptions);
  }

  updatePago(id, data): Observable<any> {
    return this.http.post(this.rootUrl + '/pagos/' + id, data);
  }

  updateImgPago(id, data): Observable<any> {
    return this.http.post(this.rootUrl + '/pagos/img/' + id, data);
  }

  /** DELETE pago to the server */
  deletePago(id): Observable<any> {
    return this.http.delete(this.rootUrl + '/pagos/' + id, httpOptions);
  }

  /** POST usuarios to the server */
  addUsuario(data: FormData): Observable<any> {
    return this.http.post(this.rootUrl + '/usuarios', data, httpOptions);
  }

  /** GET usuarios to the server */
  getUsuarios(page = 1, data): Observable<any> {
    return this.http.get(this.rootUrl + '/usuarios?page=' + page, {
      headers: headers,
      params: data,
    });
  }

  /** GET usuarios to the server */
  getUsuario(id): Observable<any> {
    return this.http.get(this.rootUrl + '/usuarios/' + id, httpOptions);
  }

  /** POST usuarios to the server */
  updateUsuario(id, data): Observable<any> {
    return this.http.post(this.rootUrl + '/usuarios/' + id, data, httpOptions);
  }

  /** DELETE pago to the server */
  deleteUsuario(id): Observable<any> {
    return this.http.delete(this.rootUrl + '/usuarios/' + id, httpOptions);
  }

  getConstancia(id): Observable<any> {
    return this.http.get(this.rootUrl + '/constancias/' + id, httpOptions);
  }

  buscarConstancia(data): Observable<any> {
    return this.http.post(this.rootUrl + '/constancias/buscar', data, httpOptions);
  }

  buscarReconocimiento(data): Observable<any> {
    return this.http.post(this.rootUrl + '/constancias/buscar-reconocimiento', data, httpOptions);
  }

  buscarConstanciaTaller(data): Observable<any> {
    return this.http.post(this.rootUrl + '/constancias/buscar-taller', data, httpOptions);
  }

  buscarConstanciaPonente(data): Observable<any> {
    return this.http.post(this.rootUrl + '/constancias/buscar-cr', data, httpOptions);
  }
  
}
