import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'checkin', loadChildren: './checkin/checkin.module#CheckinPageModule' },
  { path: 'registro', loadChildren: './registro/registro.module#RegistroPageModule' },
  { path: 'perfil', loadChildren: './perfil/perfil.module#PerfilPageModule' },
  { path: 'pagos', loadChildren: './pagos/pagos.module#PagosPageModule' },
  { path: 'pagos-create', loadChildren: './pagos-create/pagos-create.module#PagosCreatePageModule' },
  { path: 'pagos-update/:id', loadChildren: './pagos-update/pagos-update.module#PagosUpdatePageModule' },
  { path: 'usuarios', loadChildren: './usuarios/usuarios.module#UsuariosPageModule' },
  { path: 'usuarios-create', loadChildren: './usuarios-create/usuarios-create.module#UsuariosCreatePageModule' },
  { path: 'usuarios-update/:id', loadChildren: './usuarios-update/usuarios-update.module#UsuariosUpdatePageModule' },
  { path: 'reportes', loadChildren: './reportes/reportes.module#ReportesPageModule' },
  { path: 'recuperar-pass', loadChildren: './recuperar-pass/recuperar-pass.module#RecuperarPassPageModule' },
  { path: 'pass', loadChildren: './pass/pass.module#PassPageModule' },
  { path: 'pass/:id', loadChildren: './pass/pass.module#PassPageModule' },
  { path: 'preregistro/:id', loadChildren: './preregistro/preregistro.module#PreregistroPageModule' },
  { path: 'qr', loadChildren: './qr/qr.module#QrPageModule' },
  { path: 'acerca', loadChildren: './acerca/acerca.module#AcercaPageModule' },
  { path: 'becarios', loadChildren: './becarios/becarios.module#BecariosPageModule' },
  { path: 'becarios-create', loadChildren: './becarios-create/becarios-create.module#BecariosCreatePageModule' },
  { path: 'becarios-update/:id', loadChildren: './becarios-update/becarios-update.module#BecariosUpdatePageModule' },
  { path: 'gafetes', loadChildren: './gafetes/gafetes.module#GafetesPageModule' },
  { path: 'preregistro-rapido', loadChildren: './preregistro-rapido/preregistro-rapido.module#PreregistroRapidoPageModule' },
  { path: 'pagos-create-admin', loadChildren: './pagos-create-admin/pagos-create-admin.module#PagosCreateAdminPageModule' },
  { path: 'pagos-new-admin/:id', loadChildren: './pagos-new-admin/pagos-new-admin.module#PagosNewAdminPageModule' },
  { path: 'constancias', loadChildren: './constancias/constancias.module#ConstanciasPageModule' },
  { path: 'constancia', loadChildren: './constancia/constancia.module#ConstanciaPageModule' },
  { path: 'consulta-constancia', loadChildren: './consulta-constancia/consulta-constancia.module#ConsultaConstanciaPageModule' },
  { path: 'consulta-constancia/:id', loadChildren: './consulta-constancia/consulta-constancia.module#ConsultaConstanciaPageModule' },
  { path: 'consulta-reconocimiento', loadChildren: './consulta-reconocimiento/consulta-reconocimiento.module#ConsultaReconocimientoPageModule' },
  { path: 'consulta-reconocimiento/:id', loadChildren: './consulta-reconocimiento/consulta-reconocimiento.module#ConsultaReconocimientoPageModule' },
  { path: 'consulta-taller-constancia', loadChildren: './consulta-taller-constancia/consulta-taller-constancia.module#ConsultaTallerConstanciaPageModule' },
  { path: 'consulta-taller-constancia/:id', loadChildren: './consulta-taller-constancia/consulta-taller-constancia.module#ConsultaTallerConstanciaPageModule' },
  { path: 'consulta-cr', loadChildren: './consulta-cr/consulta-cr.module#ConsultaCrPageModule' },
  { path: 'consulta-cr/:id', loadChildren: './consulta-cr/consulta-cr.module#ConsultaCrPageModule' },
  // { path: 'image-modal', loadChildren: './image-modal/image-modal.module#ImageModalPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
